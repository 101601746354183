import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import createRouter from './router'
import animated from 'animate.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import vueJsonExcelUmd from 'vue-json-excel'

Vue.config.productionTip = false
Vue.component("downloadExcel",vueJsonExcelUmd)
let router = createRouter()
Vue.use(VueRouter)
Vue.use(animated)
Vue.use(ElementUI)
new Vue({
  render: h => h(App),
  router: router,
}).$mount('#app')
