import indexPage from '../pages/indexpage/index.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

var routes=[
    { path: '/index',name:'indexPage', component: indexPage },
    { path: '/',name:'indexPage', component: indexPage }
]
export default ()=>{
    return new VueRouter({routes})
} 