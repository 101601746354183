<template>
  <div class="indexPage">
    <transition
      name="fade"
      enter-active-class="animated fadeInDown"
      leave-active-class="animated fadeOutUp"
      :duration="500"
    >
      <div class="container_box">
        <el-form ref="form" :model="form" label-width="120px">
          <el-row>
            <el-col class="line" :span="4"
              ><el-form-item label="分数下限">
                <el-input v-model="form.floorLimit"></el-input>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="4">
              <el-form-item label="投标报价分">
                <el-input v-model="form.totalPoint"></el-input>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="4">
              <el-form-item label="高于均价扣分">
                <el-input v-model="form.overPoint"></el-input>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="4">
              <el-form-item label="低于均价扣分">
                <el-input v-model="form.underPoint"></el-input>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="4">
              <el-form-item label="项目名称">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="4">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">计算</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-button type="primary" @click="add">新增</el-button>
            <download-excel
              class="downLoadWapper el-button el-button--primary"
              type="primary"
              :data="form.companyList"
              :fields="fields"
              :header="form.name"
              name="报价评分表"
              :footer="'平均价格：' + average"
              >导出</download-excel
            >
            <input type="file" @change="handleFileUpload" name="导入" />
            <a href="/模板.xls" download="模板.xls">下载模板</a>
            <!-- <el-button type="primary" @click="downloadFn">下载模板</el-button> -->
            <!-- <el-upload
              class="upload-demo"
              action=""
              :limit="1"
              :file-list="fileList"
              :on-progress="handleFileUpload"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传jpg/png文件，且不超过500kb
              </div>
            </el-upload> -->
          </el-row>

          <el-table
            :data="form.companyList"
            style="width: 1800"
            max-height="900"
            :border="true"
            cell-class-name="cellClassName"
            class="ps_table"
          >
            <el-table-column
              :label="form.name"
              header-align="center"
              width="1800"
            >
              <el-table-column type="index" width="80"> </el-table-column>
              <el-table-column prop="name" label="公司名" width="480">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.name"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="quotedPrice" label="报价" width="180">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.quotedPrice"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="deviationRatio" label="偏差率">
                <template slot-scope="scope">
                  {{ (scope.row.deviationRatio * 100) | numberToString }}%
                </template>
              </el-table-column>
              <el-table-column prop="deductionPoints" label="扣分">
                <template slot-scope="scope">
                  {{ scope.row.deductionPoints | numberToString }}
                </template>
              </el-table-column>
              <el-table-column prop="score" label="得分">
                <template slot-scope="scope">
                  {{ scope.row.score | numberToString }}
                </template>
              </el-table-column>
              <el-table-column prop="ranking" label="排名" sortable>
              </el-table-column>
              <el-table-column label="操作"
                ><template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    >删除</el-button
                  >
                </template></el-table-column
              >
            </el-table-column>
          </el-table>
        </el-form>
        <div>均价：{{ average | numberToString }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
export default {
  name: "indexPage",
  created() {},
  mounted() {},
  data() {
    return {
      form: {
        floorLimit: 35,
        totalPoint: 45,
        overPoint: 1,
        underPoint: 0.5,
        companyList: [],
        name: "",
      },
      excelData: null,
      minNum: 0,
      maxNum: 0,
      average: 0,
      sortedArr: [],
      scoreArr: [],
      fields: {
        公司名: "name",
        报价: "quotedPrice",
        偏差率: "deviationRatioStr",
        扣分: "deductionPointsStr",
        得分: "scoreStr",
        排名: "ranking",
      },
      fileList: [],
      //     name: "",
      //     quotedPrice: 0,
      //     deviationRatio: "",
      //     deductionPoints: 0,
      //     score: 0,
      //     ranking: "",
    };
  },
  methods: {
    onSubmit() {
      // 计算平均分
      this.scoreArr = [];
      if (this.form.companyList.length > 0) {
        this.calculateAverageWithoutExtremes();
        this.form.companyList.map((item) => {
          item.deviationRatio = parseFloat(
            (
              (Math.abs(item.quotedPrice - this.average) * 100) /
              this.average /
              100
            ).toFixed(4)
          );
          item.deviationRatioStr =
            this.numberToString(item.deviationRatio) * 100 + "%";
          // console.log("item.deviationRatio==>",item.deviationRatio)
          if (item.quotedPrice >= this.average) {
            item.deductionPoints =
              item.deviationRatio * Number(this.form.overPoint) * 100;
          } else {
            item.deductionPoints =
              item.deviationRatio * Number(this.form.underPoint) * 100;
          }
          item.deductionPointsStr = this.numberToString(item.deductionPoints);
          // console.log("item.deductionPoints==>",item.deductionPoints)
          if (
            this.form.totalPoint - item.deductionPoints >=
            this.form.floorLimit
          ) {
            item.score = this.form.totalPoint - item.deductionPoints;
          } else {
            item.score = this.form.floorLimit;
          }
          item.scoreStr = this.numberToString(item.score);
          this.scoreArr.push(item.score);
          // let ranking = sortedArr.indexOf(Number(item.quotedPrice))
          // console.log("ranking==>",ranking)
          // item.ranking = ranking==-1?"/":ranking+1
        });
        this.scoreArr = this.scoreArr.sort((a, b) => b - a);
        this.form.companyList.map((item) => {
          let ranking = this.scoreArr.indexOf(Number(item.score));
          console.log("ranking==>", ranking);
          item.ranking = ranking == -1 ? "/" : ranking + 1;
        });
      } else {
        this.$message({
          message: "请至少增加一个公司信息",
          type: "warning",
        });
      }
    },
    calculateAverageWithoutExtremes() {
      // let averageScore = 0;
      let sortedArr = [];

      let quotedPriceList = this.form.companyList.map((item) =>
        Number(item.quotedPrice)
      );
      // console.log("averageScore", averageScore);
      // console.log("form", this.form);
      // console.log("quotedPriceList", quotedPriceList);
      if (quotedPriceList.length >= 5) {
        sortedArr = quotedPriceList.sort((a, b) => a - b);
        // 去掉最大值和最小值
        this.minNum = sortedArr.shift(); // 去掉最小值
        // console.log("minNum", this.minNum);
        this.maxNum = sortedArr.pop(); // 去掉最大值
        // console.log("maxNum", this.maxNum);
        // 计算剩余元素的平均数
        let sum = sortedArr.reduce((total, num) => total + num, 0);
        this.average = sum / sortedArr.length;
      } else {
        sortedArr = quotedPriceList.sort((a, b) => a - b);
        this.maxNum = sortedArr[0];
        this.maxNum = sortedArr[sortedArr.length - 1];
        let sum = sortedArr.reduce((total, num) => total + num, 0);
        this.average = sum / sortedArr.length;
      }
      // console.log("average", this.average);
    },
    add(obj) {
      let companyObj = {
        name: "",
        quotedPrice: 0,
        deviationRatio: "",
        deviationRatioStr: "",
        deductionPoints: 0,
        deductionPointsStr: "",
        score: 0,
        scoreStr: "",
        ranking: "",
      };
      if (obj.name || obj.quotedPrice) {
        companyObj.name = obj.name;
        companyObj.quotedPrice = obj.quotedPrice ? obj.quotedPrice : 0;
      }
      this.form.companyList.push(companyObj);
      console.log("this.form.companyList=>", this.form.companyList);
    },
    handleDelete(index, data) {
      console.log("data=>", data);
      this.form.companyList.splice(index, 1);
    },
    numberToString(value) {
      let realValue = "";
      if (!isNaN(value) && value !== "") {
        if (value === Math.floor(value)) {
          realValue = value;
        } else {
          realValue = parseFloat(value).toFixed(2);
        }
      }
      return realValue;
    },
    handleFileUpload(event) {
      console.log("event==>", event);
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        this.excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        console.log("this.excelData==>", this.excelData);
        if (this.excelData.length > 0) {
          this.form.companyList.splice(0,this.form.companyList.length)
          this.excelData.map((item, index) => {
            console.log("this.index==>", index);
            if (index >= 1) {
              let obj = {
                name: item[0],
                quotedPrice: item[1],
              };
              this.add(obj);
            }
          });
        }
      };
      reader.readAsArrayBuffer(file);
    },
    downloadFn(){
      // let downloadUrl='/模板.xls'

    },
    handleExceed(event, file, fileList) {
      console.log("event=handleExceed=>", event);
      console.log("event=file=>", file);
      console.log("event=fileList=>", fileList);
    },
  },
  filters: {
    numberToString(value) {
      let realValue = "";
      if (!isNaN(value) && value !== "") {
        if (value === Math.floor(value)) {
          realValue = value;
        } else {
          realValue = parseFloat(value).toFixed(2);
        }
      }
      return realValue;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.container_box {
  padding-top: 30px;
}
</style>
<style lang="less">
.ps_table {
  .el-table__body {
    .cellClassName {
      padding: 3px 0;
    }
  }
}
</style>